import * as React from 'react'
import arrow from '../../../images/menu-arrow.svg'
import { Link } from '@reach/router'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import punchIniOSIcon from '../../../images/punchin-ios-icon-menu.png'
import somethingIcon from '../../../images/something-icon-menu.png'
import tjingIcon from '../../../images/tjing-icon-menu.png'
import ouffIcon from '../../../images/ouff-icon.png'
import { useEffect, useRef } from 'react'

const images = { punchIniOSIcon, somethingIcon, tjingIcon, ouffIcon }

const dropdownItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: -20,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const dropdownWrapperVariants = {
  open: {
    display: 'block',
    height: 'auto',
    transition: { staggerChildren: 0.045, delayChildren: 0.15 },
  },
  closed: {
    display: 'none',
    height: 0,
    transition: {
      staggerChildren: 0.02,
      staggerDirection: -1,
      // when: 'afterChildren',
      when: 'beforeChildren',
    },
  },
}

const MenuItemWrapper = styled(motion.li)`
  list-style: none;
  display: flex;
  cursor: pointer;
  font-family: Gilroy-Semibold, sans-serif;
  font-size: 30px;
  margin-left: 50px;

  > a {
    color: #323232;
    font-size: 16px;
    :hover {
      color: #323232;
      text-decoration: none;
    }
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      background: #323232;
      transition: width 0.3s ease;
    }
    &:hover::after {
      width: 100%;
    }
  }
`

const MenuItemDropdownWrapper = styled('button')`
  cursor: pointer;
  border: 0;
  background: none;
  color: #323232;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;

  div::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: #323232;
    transition: width 0.3s ease;
  }
  div:hover::after {
    width: 100%;
  }

  img {
    width: 12px;
    height: 12px;
    margin-left: 10px;
    transition: transform 0.3s;
    ${({ isDropdownOpen }) => isDropdownOpen && 'transform: rotate(-180deg);'};
  }
`

const DropdownItem = styled(motion.li)`
  font-size: 25px;
  list-style: none;
  width: 100%;
  display: flex;
  padding: 5px;
  border-radius: 8px;

  img {
    width: 60px;
    height: 60px;
    padding: 0.5rem;
  }
  a {
    text-decoration: none;
    color: #323232;
    font-size: 16px;
  }

  :hover {
    background: #f1f1f1;
  }
`

const DropdownWrapper = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 8px;
  position: absolute;
  top: calc(100% + 12px);
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  background: #fff;
  min-width: 240px;
`

const DropdownItemImage = styled('img')`
  color: #323232;
  width: 30px;
`

const ConditinalWrapperLink = ({ linkTo, toggleOpen, children }) => {
  const { page, href } = linkTo

  if (page) {
    return (
      <Link onClick={toggleOpen} to={page}>
        {children}
      </Link>
    )
  }
  if (href) {
    return (
      <a onClick={toggleOpen} href={href}>
        {children}
      </a>
    )
  }
}

const MenuItem = ({
  dropdownItems,
  linkTo,
  name,
  menuItem,
  expanded,
  setExpanded,
}) => {
  const isDropdownOpen = menuItem === expanded
  const ref = useRef()

  const toggleDropdown = () => {
    setExpanded(isDropdownOpen ? false : menuItem)
  }

  useEffect(
    () => {
      const handleClickOutside = e => {
        if (ref.current && !ref.current.contains(e.target)) {
          if (isDropdownOpen) {
            toggleDropdown()
          }
        }
      }
      document.addEventListener('click', handleClickOutside)
      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    },
    [isDropdownOpen]
  )

  return (
    <MenuItemWrapper>
      {dropdownItems ? (
        <div ref={ref}>
          <MenuItemDropdownWrapper
            onClick={toggleDropdown}
            isDropdownOpen={isDropdownOpen}
            innerRef={ref}
          >
            <div>
              {name}
              <img src={arrow} alt="Dropdown indicator" />
            </div>
          </MenuItemDropdownWrapper>
          <DropdownWrapper
            initial={false}
            animate={isDropdownOpen && expanded ? 'open' : 'closed'}
            variants={dropdownWrapperVariants}
          >
            {dropdownItems.map(dropdownItem => (
              <DropdownItem
                variants={dropdownItemVariants}
                key={dropdownItem.iconName}
              >
                <ConditinalWrapperLink
                  toggleOpen={toggleDropdown}
                  linkTo={dropdownItem.linkTo}
                >
                  {dropdownItem.iconName && (
                    <DropdownItemImage
                      src={images[`${dropdownItem.iconName}Icon`]}
                      alt="App logo image"
                    />
                  )}
                  {dropdownItem.name}
                </ConditinalWrapperLink>
              </DropdownItem>
            ))}
          </DropdownWrapper>
        </div>
      ) : (
        <ConditinalWrapperLink toggleOpen={toggleDropdown} linkTo={linkTo}>
          {name}
        </ConditinalWrapperLink>
      )}
    </MenuItemWrapper>
  )
}

export default MenuItem
