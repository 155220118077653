import * as React from 'react'
import { useState } from 'react'
import { motion } from 'framer-motion'
import { MenuItem } from './MenuItem'
import menuItems from '../menuItems'

const variants = {
  open: {
    display: 'block',
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    display: 'none',
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: 'afterChildren',
    },
  },
}

export const Navigation = ({ toggleOpen, expanded, setExpanded }) => {
  // const [expanded, setExpanded] = useState({})

  return (
    <motion.ul variants={variants} className="mobileMenu-content">
      {menuItems.map(menuItem => (
        <MenuItem
          expanded={expanded}
          setExpanded={setExpanded}
          toggleOpen={toggleOpen}
          key={menuItem.name}
          menuItem={menuItem}
          {...menuItem}
        />
      ))}
    </motion.ul>
  )
}
