import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'
import kidpilotLogo from '../images/logohead@2x.png'
import MobileMenu from './navigation/mobileMenu/MobileMenu'
import DesktopMenu from './navigation/desktopMenu/DesktopMenu'
import styled from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

// state = {
//   x: 0,
//   y: 0,
//   z: 'unscrolled',
// }
// expandHamburger = () => {
//   var position = 0
//
//   if (!$('html').hasClass('open')) {
//     position = $(window).scrollTop()
//   }
//
//   $('.hamburger').toggleClass('open')
//   if ($('.hamburger').hasClass('open')) {
//     $('.navigation').addClass('open')
//
//     setTimeout(function() {
//       $('.navigation').addClass('transition')
//     }, 100)
//
//     $('body').addClass('open')
//     $('html').addClass('open')
//     $('html, body').animate({ scrollTop: position }, 0)
//   } else {
//     position = $(window).scrollTop()
//     $('.navigation').removeClass('transition')
//     setTimeout(function() {
//       $('.navigation').removeClass('open')
//     }, 500)
//
//     $('body').removeClass('open')
//     $('html').removeClass('open')
//     $('html, body').animate(
//       {
//         scrollTop: position,
//       },
//       0
//     )
//   }
// }
//
// closeHamburger = () => {
//   setTimeout(function() {
//     $('.hamburger').removeClass('open')
//     $('.navigation').removeClass('transition')
//     $('.navigation').removeClass('open')
//     $('body').removeClass('open')
//     $('html').removeClass('open')
//   }, 500)
// }

const SiteLogo = styled('div')`
  position: fixed;
  z-index: 11;
  top: 0.8rem;
  a {
    padding: 1rem;
  }
  img {
    width: 35px;
    height: 35px;
    transition: transform 0.3s;
    :hover {
      transform: rotate(12deg);
    }
  }

  @media (min-width: 767px) {
    top: 1.8rem;
    img {
        // width: ${({ scrolled }) => (scrolled ? '30px' : '45px')};
        // height: ${({ scrolled }) => (scrolled ? '30px' : '45px')};
      width: 45px;
      height: 45px;
      margin-left: 20px;
    }
  }
`

const Header = () => {
  const [scrolled, setScrolled] = useState(false)
  const [clickedLogo, setClickedLogo] = useState(false)
  const breakpoints = useBreakpoint()

  const setClicked = () => {
    setClickedLogo(!clickedLogo)
  }

  // TODO aktivera nedanstående om vi vill ha scrolled state på menyn
  // useEffect(
  //   () => {
  //     const handleScroll = () => {
  //       const scrollPosition = window.pageYOffset
  //       if (scrollPosition > 155 && !scrolled) {
  //         setScrolled(true)
  //       } else if (scrollPosition <= 155 && scrolled) {
  //         setScrolled(false)
  //       }
  //     }
  //     document.addEventListener('scroll', handleScroll)
  //     return () => {
  //       document.removeEventListener('scroll', handleScroll)
  //     }
  //   },
  //   [scrolled]
  // )

  return (
    <div>
      <SiteLogo onClick={setClicked} scrolled={scrolled}>
        <Link to="/">
          <img src={kidpilotLogo} alt="Kidpilot Logo" />
        </Link>
      </SiteLogo>
      {breakpoints.mobile ? (
        <MobileMenu clickedLogo={clickedLogo} />
      ) : (
        <DesktopMenu clickedLogo={clickedLogo} />
      )}
      {/*<Media queries={{ mobile: '(max-width: 766px)' }}>*/}
      {/*  {matches =>*/}
      {/*    matches.mobile ? (*/}
      {/*      <MobileMenu clickedLogo={clickedLogo} />*/}
      {/*    ) : (*/}
      {/*      <DesktopMenu clickedLogo={clickedLogo} />*/}
      {/*    )*/}
      {/*  }*/}
      {/*</Media>*/}
    </div>
    // <nav className={`navbar ${this.state.z}`}>
    //   <div className="brand">
    //     <Link className="navbar-brand" to="/">
    //       <img className="kidpilot" src={kidpilotLogo} alt="Kidpilot Logo" />
    //     </Link>
    //   </div>
    //   {/*<div className="hamburger d-md-none" onClick={this.expandHamburger}>*/}
    //   {/*  <span />*/}
    //   {/*  <span />*/}
    //   {/*  <span />*/}
    //   {/*  <span />*/}
    //   {/*</div>*/}
    //   {/*<Media queries={{ mobile: "(max-width: 766px)" }}>*/}
    //   {/*  {matches =>*/}
    //   {/*    matches.mobile ? (*/}
    //   {/*      <MobileMenu />*/}
    //   {/*    ) : (*/}
    //   {/*      <p>The document is at least 600px wide.</p>*/}
    //   {/*    )*/}
    //   {/*  }*/}
    //   {/*</Media>*/}
    //   {/*<div className="navigation">*/}
    //   {/*  <ul className="nav flex-row">*/}
    //   {/*    <li className="nav-item dropdown">*/}
    //   {/*      <a*/}
    //   {/*        className="nav-link dropdown-toggle"*/}
    //   {/*        data-toggle="dropdown"*/}
    //   {/*        href="#"*/}
    //   {/*        role="button"*/}
    //   {/*        aria-haspopup="true"*/}
    //   {/*        aria-expanded="false"*/}
    //   {/*      >*/}
    //   {/*        Apps*/}
    //   {/*        <img className="menu-arrow" src={arrow} alt="Toggler Arrow" />*/}
    //   {/*      </a>*/}
    //   {/*      <ul className="dropdown-menu">*/}
    //   {/*        <li>*/}
    //   {/*          <Link*/}
    //   {/*            className="dropdown-item"*/}
    //   {/*            to="/something"*/}
    //   {/*            onClick={this.closeHamburger}*/}
    //   {/*          >*/}
    //   {/*            <img*/}
    //   {/*              className="menu-icon"*/}
    //   {/*              src={somethingIcon}*/}
    //   {/*              alt="Something for iOS"*/}
    //   {/*            />*/}
    //   {/*            Something*/}
    //   {/*          </Link>*/}
    //   {/*        </li>*/}
    //   {/*        <li>*/}
    //   {/*          <Link*/}
    //   {/*            className="dropdown-item"*/}
    //   {/*            to="/punchin/ios"*/}
    //   {/*            onClick={this.closeHamburger}*/}
    //   {/*          >*/}
    //   {/*            <img*/}
    //   {/*              className="menu-icon"*/}
    //   {/*              src={punchiniOSIcon}*/}
    //   {/*              alt="Punch In for iOS"*/}
    //   {/*            />*/}
    //   {/*            Punch In*/}
    //   {/*          </Link>*/}
    //   {/*        </li>*/}
    //   {/*        <li>*/}
    //   {/*          <Link*/}
    //   {/*            className="dropdown-item"*/}
    //   {/*            to="/ouff"*/}
    //   {/*            onClick={this.closeHamburger}*/}
    //   {/*          >*/}
    //   {/*            <img*/}
    //   {/*              className="menu-icon"*/}
    //   {/*              src={ouffIcon}*/}
    //   {/*              alt="Ouff - Daily insults"*/}
    //   {/*            />*/}
    //   {/*            Ouff*/}
    //   {/*          </Link>*/}
    //   {/*        </li>*/}
    //   {/*        <li*/}
    //   {/*          onClick={this.closeHamburger}*/}
    //   {/*        >*/}
    //   {/*          <a*/}
    //   {/*            className="dropdown-item"*/}
    //   {/*            href="https://tjing.se/"*/}
    //   {/*          >*/}
    //   {/*            <img*/}
    //   {/*              className="menu-icon"*/}
    //   {/*              src={tjingIcon}*/}
    //   {/*              alt="Tjing Disc Golf"*/}
    //   {/*            />*/}
    //   {/*            Tjing*/}
    //   {/*          </a>*/}
    //   {/*        </li>*/}
    //   {/*      </ul>*/}
    //   {/*    </li>*/}
    //   {/*    <li className="nav-item">*/}
    //   {/*      <Link to="/company" onClick={this.closeHamburger}>*/}
    //   {/*        Company*/}
    //   {/*      </Link>*/}
    //   {/*    </li>*/}
    //   {/*    <li className="nav-item">*/}
    //   {/*      <Link to="/blog" onClick={this.closeHamburger}>*/}
    //   {/*        Blog*/}
    //   {/*      </Link>*/}
    //   {/*    </li>*/}
    //   {/*  </ul>*/}
    //   {/*</div>*/}
    // </nav>
  )
}
export default Header
